<template src="./index.html"></template>
<style src="./index.less" lang="less" scoped></style>

<script>
import { mapState } from "vuex";
import mySearchForm from "@/UI/mySearchForm";
import index from "@/components/singleprint/index";
import ResultInfo from "@/components/singleprint/ResultInfo";
import progressBarPopup from "@/UI/progressBarPopup";

export default {
  components: {
    mySearchForm,
    index,
    ResultInfo,
    progressBarPopup,
  },
  data() {
    return {
      backgroundColor: '',
      searchInfoShow:false,
      selectMsg:'',
      showTitle:true
    }
  },
  computed: {
    ...mapState({
      isShow: (state) => state.singleprint.isShow,
      orderInfo: (state) =>state.singleprint.orderInfo,
      circularType: (state) => state.singleprint.circularType,
      searchFormData: (state) => state.singleprint.searchFormData,
      resultLoading: (state) => state.singleprint.resultLoading,
      resultList: (state) => state.singleprint.resultList,
      pageHide: (state) => state.singleprint.pageHide,
      orderLoading: (state) => state.singleprint.orderLoading,
      progressBarPopup:(state) => state.singleprint.progressBarPopup,
      singleprint_nodata: (state) => state.singleprint.singleprint_nodata,
    }),
  },
  methods: {
    // searchForm查询条件更新函数
    updateFormData(val) {
      console.log(val,'---val');
      this.searchFormData = val;
    },
    // 点击查询按钮函数
    submitSearch() {
      this.searchInfoShow=false
      setTimeout(() => {
        this.searchInfoShow=true
      }, 0);
      this.searchFormData.pull_data = 1 // 标识是否拉取新数据
      this.$store.dispatch("singleprint/get_search_result").then(res=>{
        this.searchFormData.pull_data = 0 // 使用完成后还原
      });
      this.$store.commit("singleprint/SET_ISSHOW", "index");
      this.$store.commit("singleprint/SET_PAGENUM", 1);
       
    },
    // 查看订单详情函数
    showInfo(item, transaction_id,index) {
      if(item.merge_order){
        for(let j in item.merge_order){
          item.merge_order[j].editRemarks=false
          item.merge_order[j].falgRadio_temp=''
          item.merge_order[j].seller_memo_value=''
          item.merge_order[j].clickNumber=index
        }
      }
      this.$store.commit("singleprint/SET_PRINT_ORDER_INFO", item);
      let itemList = JSON.parse(JSON.stringify(item))
      for(let i in itemList.order_sub) {
        itemList.order_sub[i].checkedType = true
        itemList.order_sub[i].inputType = false
        itemList.order_sub[i].inputValue = ''
      }
      this.$store.commit("singleprint/SET_ORDERINFO", itemList);
      let data={}
      data['orderId']=item.transaction_id;
      data['caid']=item.caid;
      // this.$store.dispatch('singleprint/decryptBuyer',data);
      this.$store.commit("singleprint/SET_ORDERID", transaction_id);
      this.$store.commit("singleprint/SET_ISSHOW", "ResultInfo");
      this.$store.commit("singleprint/SET_ORDER_LOADING", false);
      // this.$store.dispatch('singleprint/get_order_info');
     
    },
    // 换页函数
    handleCurrentChange(page) {
      this.$store.commit("singleprint/SET_ISSHOW", "index");
      this.$store.commit("singleprint/SET_PAGENUM", page);
      this.$store.dispatch("singleprint/get_search_result");
    },
    textJump() {
      let path = `${window.location.protocol}//${window.location.host}/#/usetutorial`
      window.open(path);
    },
  },
   updated() {
    if(this.searchFormData.status==0){
      this.selectMsg='全部订单'
    }
    if(this.searchFormData.status==1){
      this.selectMsg='等待买家付款'
    }
    if(this.searchFormData.status==2){
      this.selectMsg='买家已付款'
    }
    if(this.searchFormData.status==3){
      this.selectMsg='卖家已发货'
    }
    if(this.searchFormData.status==4){
      this.selectMsg='交易成功'
    }
  },
  created() {
    this.$store.commit("singleprint/save",{singleprint_nodata:true})
    this.$store.commit("singleprint/SET_ISSHOW", "index");
  },
};
</script>
