<template>
  <div class="result-container">
    <div class="row1">
      <div class="row1-header">
        <div>
        <span style="margin-left: 10px; margin-right: 20px">{{
          orderInfo.buyer_login_id
        }}</span>
        <span v-if="orderInfo.express_print_state=='1'" style="color:#488b00;margin-right:5px">✔</span>
        <span v-if="orderInfo.invoice_print_state=='1'" style="color:#3da6e3;margin-right:5px">✔</span>
        <!-- <p class="circularClass" @click="circularTypeChange(1)" :style="{'backgroundColor': orderInfo.circularType=='1' ?'#fb0404' :'','borderColor':'#fb0404'}"></p>
        <p class="circularClass" @click="circularTypeChange(2)" :style="{'backgroundColor': orderInfo.circularType=='2' ?'#67b835' :'','borderColor':'#67b835'}"></p>
        <p class="circularClass" @click="circularTypeChange(3)" :style="{'backgroundColor': orderInfo.circularType=='3' ?'#3c93c4' :'','borderColor':'#3c93c4'}"></p>
        <p class="circularClass" @click="circularTypeChange(4)" :style="{'backgroundColor': orderInfo.circularType=='4' ?'#f8b40d' :'','borderColor':'#f8b40d'}"></p>
        <p class="circularClass" @click="circularTypeChange(5)" :style="{'backgroundColor': orderInfo.circularType=='5' ?'#b52bd7' :'','borderColor':'#b52bd7'}"></p> -->
        <el-button
          size="mini"
          v-clipboard:copy="orderInfo.buyer_login_id"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          >复制名字</el-button
        ></div>
        <span v-if="orderInfo.logistics_bill_no">快递单号：{{orderInfo.logistics_bill_no}}</span>
        <!-- <el-image
          style="width: 20px; height: 20px"
          :src="showAll ? OSS+('/show.svg') : OSS+('/hidden.svg')"
          @click="showInfo(showAll)"
          ></el-image> -->
      </div>
      <el-divider></el-divider>
      <div class="row1-body">
        <table border="0" class="body-infotable">
          <tr>
            <td class="table-key">收件人:</td>
            <td class="table-value">{{ showAll ? decryptInfo.contactPerson : orderInfo.to_full_name }}
            </td>
          </tr>
          <tr>
            <td class="table-key">电话:</td>
            <td class="table-value">{{  showAll ? decryptInfo.mobile : orderInfo.to_mobile }}</td>
          </tr>
          <tr>
            <td class="table-key">邮编:</td>
            <td class="table-value">{{ orderInfo.to_division_code }}</td>
          </tr>
          <tr>
            <td class="table-key">地址:</td>
            <td class="table-value" v-if="addressEdit == false">
              <span>{{
                orderInfo.province +
                " " +
                orderInfo.city +
                " " +
                orderInfo.area +
                " " +
                orderInfo.town +
                " " +
                (showAll ? decryptInfo.address : orderInfo.address)
              }}</span>
              <el-button type="text" v-if="orderInfo.showUpAdres==0" @click="showAddressSelect()"
                ><i class="el-icon-edit"></i>
              </el-button
              >
            </td>
            <td class="table-menu" v-if="addressEdit == true">
              <el-form
                :inline="true"
                style="display: inline-block; margin-right: 20px"
              >
                <el-form-item>
                  <el-select
                    v-model="orderInfo.province"
                    placeholder="请选择省份"
                    size="mini"
                    @change="provinceChanged"
                  >
                    <el-option
                      v-for="(item, code) in provinces"
                      :key="code"
                      :label="item"
                      :value="code"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="orderInfo.city"
                    :loading="loadingCity"
                    @change="cityChanged"
                    @visible-change="cityLoad"
                    size="mini"
                    placeholder="请选择城市"
                    style="margin: auto 10px"
                  >
                    <el-option
                      v-for="(item, code) in cities"
                      :key="code"
                      :label="item"
                      :value="code"
                    >
                    </el-option>
                  </el-select>

                  <el-select
                    v-model="orderInfo.area"
                    :loading="loadingDistrict"
                    @change="districtChanged"
                    @visible-change="districtLoad"
                    size="mini"
                    placeholder="请选择地区"
                  >
                    <el-option
                      v-for="(item, code) in districts"
                      :key="code"
                      :label="item"
                      :value="code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-input
                    v-model="orderInfo.town"
                    placeholder="请输入街道"
                    size="mini"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    v-model="orderInfo.address"
                    placeholder="请输入详细地址"
                    size="mini"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button size="mini" @click="updateAddress()"
                    >确定</el-button>
                 <el-button
                          type="warning"
                          size="mini"
                          @click="hideAddressSelect()"
                          >取消</el-button
                        >
                  <span style="font-size: 12px">（不会改变阿里订单地址）</span>
                </el-form-item>
              </el-form>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- <div class="row2">
        <span class="row2-key">可到达快递:</span>
        <span class="row2-item">邮政</span>
        <span class="row2-item">邮政国内小包</span>
        <span class="row2-item">EMS</span>
        <span class="row2-item row-item-active">EMS经济快递</span>
        <span class="row2-item">百世汇通</span>
        <span class="row2-item">圆通快递</span>
        <span class="row2-item">天天快递</span>
        <span class="row2-item row-item-active">全峰快递</span>
        <span class="row2-item">宅急送</span>
        <span class="row2-item">申通快递</span>
        <span class="row2-item">中通快递</span>
        <span class="row2-item">韵达快递</span>
    </div> -->
    <div v-if="orderInfo.is_merge_order==0">
      <div class="row3" :style="{marginBottom: flagShow ?'100px' :''}">
      <div class="row3-header">
        <span>
          <span style="margin-right: 5px">订单编号:</span>
          <span class="header-item">{{ orderInfo.transaction_id }}</span>
        </span>
        <span class="header-item">{{ orderInfo.create_time }}</span>
        <span class="header-item">{{
          orderInfo.status | orderStatusFormat
        }}</span>
            <span>
          <span style="margin-right: 5px">订单金额:</span>
          <span class="header-item">{{orderInfo.sum_product_payment}}元</span>
        </span>
         <span style="margin-right: 25px">
          <span style="margin-right: 5px">实付:</span>
          <span class="header-item">{{orderInfo.item_amount}}元</span>
          (<span class="header-item">运费：{{orderInfo.shipping_fee}}元</span>)
        </span>
        
        <!-- <el-button type="text">挂起</el-button> -->
      </div>
      <!-- <div style="display: flex;align-items:center;padding-left:10px">
        <span style="color:#ff0000">订单标记：</span>
        <span v-if="orderInfo.express_print_state=='1'" style="color:#488b00;margin-right:5px">✔</span>
        <span v-if="orderInfo.invoice_print_state=='1'" style="color:#3da6e3;margin-right:5px">✔</span>
        <p class="circularClass" @click="circularTypeChange(1)" :style="{'backgroundColor': orderInfo.circularType=='1' ?'#fb0404' :'','borderColor':'#fb0404'}"></p>
        <p class="circularClass" @click="circularTypeChange(2)" :style="{'backgroundColor': orderInfo.circularType=='2' ?'#67b835' :'','borderColor':'#67b835'}"></p>
        <p class="circularClass" @click="circularTypeChange(3)" :style="{'backgroundColor': orderInfo.circularType=='3' ?'#3c93c4' :'','borderColor':'#3c93c4'}"></p>
        <p class="circularClass" @click="circularTypeChange(4)" :style="{'backgroundColor': orderInfo.circularType=='4' ?'#f8b40d' :'','borderColor':'#f8b40d'}"></p>
        <p class="circularClass" @click="circularTypeChange(5)" :style="{'backgroundColor': orderInfo.circularType=='5' ?'#b52bd7' :'','borderColor':'#b52bd7'}"></p>
      </div> -->
      <div class="row3-body" style="position: relative;">
        <table border="0" class="body-infotable2">
          <!-- <tr>
                <td class="table-key">订单标记:</td>
                <td class="table-value">
                <status-list/>
                </td>
            </tr> -->
          <!-- <tr>
            <td class="table-key">买家留言:</td>
            <td class="table-value">
              {{ orderInfo.buyer_feedback | nullToChinese }}
            </td>
          </tr>
          <tr>
            <td class="table-key">卖家备注:</td>
            <td class="table-value">
              <span>{{ orderInfo.seller_memo | nullToChinese }}</span>
              <el-button type="text">(更改)</el-button>
            </td>
          </tr> -->
          <!-- <tr>
            <td class="table-key">付款情况:</td>
            <td class="table-value">
              共付{{ orderInfo.total_amount }}元 （邮费:{{
                orderInfo.shipping_fee
              }}元）
            </td>
          </tr> -->
          <tr>
            <td class="table-key" style="white-space:nowrap">留言:</td>
            <td class="table-value">
              {{ orderInfo.buyer_feedback | nullToChinese }}
            </td>
          </tr>
          <tr>
            <td class="table-key" style="white-space:nowrap">备注:</td>
            <td class="table-value">
              {{ orderInfo.seller_memo | nullToChinese }}
            </td>
			      <td class="table-value">
                <!-- <el-input v-model="orderInfo.seller_memo"
                  placeholder="无" 
                  size="mini"
                  style="border:none"
                >{{ orderInfo.seller_memo | nullToChinese }}</el-input> -->
                <img class="flagClass" style="margin-left:10px" :src="FLAG_MAP[orderInfo.seller_remark_icon]" alt="">
                <el-button type="text" style="display:inline-block" @click="changeMemo">(更改)</el-button>
            </td>          
          </tr>
          <div class="table-value flagDiv" v-if="flagShow">
            <!-- <span style="color:#365064">选择旗帜：</span> -->
              <!-- <el-radio v-model="falgRadio" @change='changeFlag(0)' label="0"><img class="flagClass" style="cursor: pointer;" :src="oss+'/ashFlag.png'" alt=""></el-radio> -->
              <el-radio v-model="falgRadio" @change='changeFlag(1)' label="1"><img class="flagClass" style="cursor: pointer;" :src="OSS+'/redFlag.png'" alt=""></el-radio>
              <el-radio v-model="falgRadio" @change='changeFlag(2)' label="2"><img class="flagClass" style="cursor: pointer;" :src="OSS+'/blueFlag.png'" alt=""></el-radio>
              <el-radio v-model="falgRadio" @change='changeFlag(3)' label="3"><img class="flagClass" style="cursor: pointer;" :src="OSS+'/greenFlag.png'" alt=""></el-radio>
              <el-radio v-model="falgRadio" @change='changeFlag(4)' label="4"><img class="flagClass" style="cursor: pointer;" :src="OSS+'/yellowFlag.png'" alt=""></el-radio>
              <!-- <el-radio v-model="falgRadio" @change='changeFlag(5)' label="5"><img class="flagClass" style="cursor: pointer;" @click="changeFlag(5)" :src="oss+'/purpleFlag.png'" alt=""></el-radio> -->
            <!-- <span style="color:#365064">【更新成功】</span> -->
            <br>
            <el-input v-model="seller_memo_value"
              placeholder="无" 
              size="mini"
              style="border:none;margin-top:15px"
            >{{ seller_memo_value }}</el-input>
            <input class="handleBtn" type="button" value="保存" @click="saveMemo">
            <input class="handleBtn" type="button" value="返回" @click="flagShow = false">
          </div>
        </table>
      </div>
    </div>
    <div class="row4">
      <table class="row4-table">
          <tr v-for="(item, index) in orderInfo.order_sub" :key="index">
            <td class="table-image">
              <el-image
                style="width: 200px; height: 200px"
                :src="item.product_img_url[1]"
                fit="fill"
              ></el-image>
            </td>
            <td class="table-content">
              <div>
                <!-- <el-checkbox style="">
                        <span v-if="item.sku_short_name != ''">{{ item.sku_short_name }}</span>
                        <span v-if="item.sku_short_name == '' && item.baby_short_name != ''">{{ item.baby_short_name }}</span>
                        <span v-if="item.sku_short_name == '' && item.baby_short_name == ''">{{ item.name }}</span>
                      </el-checkbox> -->
                <div style="font-size: 14px; font-weight: 600;color:#aaa">
                  <!-- <el-checkbox v-model="item.checkedType">
                    <span style="color:#aaa" v-if="item.sku_short_name != ''" >{{
                      item.sku_short_name
                    }}</span>
                    <span
                      style="color:#aaa"
                      v-if="item.sku_short_name == '' && item.baby_short_name != ''"
                      >{{ item.baby_short_name }}</span
                    >
                    <span
                      style="color:#aaa"
                      v-if="item.sku_short_name == '' && item.baby_short_name == ''"
                      >{{ item.name }}</span
                    >
                    <el-button class="editClass" type="text" @click="editNameBtn(item,index)">编辑简称</el-button>
                  </el-checkbox> -->
                  <span style="color:#aaa" v-if="item.sku_short_name != ''" >{{
                      item.sku_short_name
                    }}</span>
                    <span
                      style="color:#aaa"
                      v-if="item.sku_short_name == '' && item.baby_short_name != ''"
                      >{{ item.baby_short_name }}</span
                    >
                    <span
                      style="color:#aaa"
                      v-if="item.sku_short_name == '' && item.baby_short_name == ''"
                      >{{ item.name }}</span
                    >
                </div>
                <div v-if="item.inputType" class="editNameClass">
                  <span style="white-space:nowrap;">简称：</span>
                  <el-input v-model="item.inputValue" size="mini" placeholder="请输入内容"></el-input>
                  <input type="button" value="设置" @click="confirmEditNameBtn(item,index)" class="editBtn">
                  <input type="button" value="取消" @click="noneEditNameBtn(item,index)" class="editBtn">
                </div>
                
              </div>
              <table border="0" class="inner-table">
                <tr>
                  <td class="table-key">数量:</td>
                  <td class="table-value">
                    <span style="background-color: #ff0000; color: #fff">{{
                      item.quantity
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>规格:</td>
                  <template v-for="(sku_item,sku_index) in item.sku_info">
                    <td class="table-key" style="color: #909399;padding-right:0px;">{{sku_item.name}}：</td>
                    <td class="table-value">
                      <span style="color: #909399">{{sku_item.value}}</span>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td class="table-key">定价:</td>
                  <td class="table-value">{{ item.price }}元</td>
                </tr>
                <tr>
                  <td class="table-key">实付:</td>
                  <td class="table-value">{{ item.item_amount }}元</td>
                </tr>
              </table>
              <span style="font-size: 14px; font-weight: 1000; color: #ff0000"
                >({{ item.statusStr }})</span
              >
              <span style="font-size: 14px; font-weight: 1000; color: #0023ff" v-if="item.refundStatusStr"
                >({{ item.refundStatusStr }})</span
              >
            </td>
          </tr>
      </table>
    </div>
    </div>
    <div v-if="orderInfo.is_merge_order==1">
   <div v-for='(ite,ind) in orderInfo.merge_order' :key="ind">
      
      <div class="row3" :style="{marginBottom: flagShow ?'100px' :''}">
      <div class="row3-header">
        <span>
          <span style="margin-right: 5px">订单编号:</span>
          <span class="header-item">{{ ite.transaction_id }}</span>
        </span>
        <span class="header-item">{{ ite.create_time }}</span>
        <span class="header-item">{{
          ite.status | orderStatusFormat
        }}</span>
            <span>
          <span style="margin-right: 5px">订单金额:</span>
          <span class="header-item">{{ite.sum_product_payment}}元</span>
        </span>
         <span style="margin-right: 25px">
          <span style="margin-right: 5px">实付:</span>
          <span class="header-item">{{ite.item_amount}}元</span>
          (<span class="header-item">运费：{{ite.shipping_fee}}元</span>)
        </span>
        
      </div>

      <div class="row3-body" style="position: relative;">
        <table border="0" class="body-infotable2">
        
          <tr>
            <td class="table-key" style="white-space:nowrap">留言:</td>
            <td class="table-value">
              {{ ite.buyer_feedback | nullToChinese }}
            </td>
          </tr>
          <tr>
            <td class="table-key" style="white-space:nowrap">备注:</td>
            <td class="table-value">
              {{ ite.seller_memo | nullToChinese }}
            </td>
			      <td class="table-value">
                <img class="flagClass" style="margin-left:10px" :src="FLAG_MAP[ite.seller_remark_icon]" alt="">
                <el-button type="text" style="display:inline-block" @click="changeMemoHe(ite.seller_memo,ind,orderInfo.merge_order)">(更改)</el-button>
            </td>          
          </tr>
          <div class="table-value flagDiv" v-if="ite.editRemarks">

              <el-radio v-model="ite.falgRadio_temp" @change='changeFlagHe("1",ite)' label="1"><img class="flagClass" style="cursor: pointer;" :src="OSS+'/redFlag.png'" alt=""></el-radio>
              <el-radio v-model="ite.falgRadio_temp" @change='changeFlagHe("2",ite)' label="2"><img class="flagClass" style="cursor: pointer;" :src="OSS+'/blueFlag.png'" alt=""></el-radio>
              <el-radio v-model="ite.falgRadio_temp" @change='changeFlagHe("3",ite)' label="3"><img class="flagClass" style="cursor: pointer;" :src="OSS+'/greenFlag.png'" alt=""></el-radio>
              <el-radio v-model="ite.falgRadio_temp" @change='changeFlagHe("4",ite)' label="4"><img class="flagClass" style="cursor: pointer;" :src="OSS+'/yellowFlag.png'" alt=""></el-radio>
            <br>
            <el-input v-model="seller_memo_value"
              placeholder="无" 
              size="mini"
              style="border:none;margin-top:15px"
            >{{ ite.seller_memo }}</el-input>
            <input class="handleBtn" type="button" value="保存" @click="saveMemoHe(ite,ind,ite.clickNumber)">
            <input class="handleBtn" type="button" value="返回" @click="ite.editRemarks = false">
          </div>
        </table>
      </div>
    </div>
    <div class="row4" :style="{marginTop: ite.editRemarks ?'100px' :'',display: ite.editRemarks ?'block' :''}">
      <table class="row4-table">
          <tr v-for="(item, index) in ite.order_sub" :key="index">
            <td class="table-image">
              <el-image
                style="width: 200px; height: 200px"
                :src="item.product_img_url[1]"
                fit="fill"
              ></el-image>
            </td>
            <td class="table-content">
              <div>
                <div style="font-size: 14px; font-weight: 600;color:#aaa">

                  <span style="color:#aaa" v-if="item.sku_short_name != ''" >{{
                      item.sku_short_name
                    }}</span>
                    <span
                      style="color:#aaa"
                      v-if="item.sku_short_name == '' && item.baby_short_name != ''"
                      >{{ item.baby_short_name }}</span
                    >
                    <span
                      style="color:#aaa"
                      v-if="item.sku_short_name == '' && item.baby_short_name == ''"
                      >{{ item.name }}</span
                    >
                </div>
                <div v-if="item.inputType" class="editNameClass">
                  <span style="white-space:nowrap;">简称：</span>
                  <el-input v-model="item.inputValue" size="mini" placeholder="请输入内容"></el-input>
                  <input type="button" value="设置" @click="confirmEditNameBtn(item,index)" class="editBtn">
                  <input type="button" value="取消" @click="noneEditNameBtn(item,index)" class="editBtn">
                </div>
                
              </div>
              <table border="0" class="inner-table">
                <tr>
                  <td class="table-key">数量:</td>
                  <td class="table-value">
                    <span style="background-color: #ff0000; color: #fff">{{
                      item.quantity
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>规格:</td>
                  <template v-for="(sku_item,sku_index) in item.sku_info">
                    <td class="table-key" style="color: #909399;padding-right:0px;">{{sku_item.name}}：</td>
                    <td class="table-value">
                      <span style="color: #909399">{{sku_item.value}}</span>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td class="table-key">定价:</td>
                  <td class="table-value">{{ item.price }}元</td>
                </tr>
                <tr>
                  <td class="table-key">实付:</td>
                  <td class="table-value">{{ item.item_amount }}元</td>
                </tr>
              </table>
              <span style="font-size: 14px; font-weight: 1000; color: #ff0000"
                >({{ item.statusStr }})</span
              >
              <span style="font-size: 14px; font-weight: 1000; color: #0023ff" v-if="item.refundStatusStr"
                >({{ item.refundStatusStr }})</span
              >
            </td>
          </tr>
      </table>
    </div>
    </div>
    </div>



    <div class="row5">
      <!-- <div class="row5-col1">
        <div class="col1-header">
          <span>要在打印单上打印的发货内容:</span>
          <div>
                <span style="margin-right:10px">设置:</span>
                <el-button type="text" style="font-size: 12px; margin-right: 20px;">打印内容</el-button>
                <el-button type="text" style="font-size: 12px;">过滤词</el-button>
            </div>
        </div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 6 }"
          placeholder="请输入内容"
          v-model="orderInfo.invoice_content"
        >
        </el-input>
      </div> -->
      
      <div class="row5-col2">
        <el-button
          class="col2-btn btn1"
          @click="printDeliverGoodsBtn"
          >打印发货单</el-button
        >
        <el-button
          class="col2-btn btn2"
          @click="printExpressBtn"
          >打印快递单</el-button
        >
      </div>
      <el-button @click="handleClickDeliverGoods" size="mini" class="row5-btn">发货</el-button>
    </div>
    <div class="row6">
      <!-- <ul>
        <li>
          <span style="color: #ff2f2f">温馨提示:</span>
        </li>
        <li>
          <span>*以上内容会打印到快递单上，如果不想打印请</span>
          <el-button
            type="text"
            style="font-size: 12px; color: #ff2f2f"
            @click="deleteTextarea"
            >删除</el-button
          >
        </li>
        <li>
            <span>*建议使用<span style="color: #7DDE70">编辑打印简称功能</span>，可以提高</span>
            <span style="color: #7DDE70">配货、发货效率</span>
            </li>
        <li>
          <span
            >*点击<span style="color: #7dde70">发货</span
            >会提示输入快递单号，只需输入一次，以后自动增加</span
          >
        </li>
      </ul> -->
    </div>
    <!-- 发货单设置发件人弹窗 -->
     <ShipFromDialog :orderInfoList='this.orderInfo' :type='2'/>
    <!-- 打印发货单弹窗 -->
    <!-- <my-dialog-3
      :centerDialogVisible="dialogFahuoPrintVisible"
      :popupWidth="'500px'"
      @updateVisible="updateFahuoPrintVisible"
    >
      <template slot="content">
        <div class="fahuo-print-dialog">
          <el-form
            label-position="right"
            label-width="120px"
            :model="fahuoPrintOption"
          >
            <el-form-item label="选择打印机：">
              <el-select
                v-model="fahuoPrintOption.printer"
                placeholder="选择打印机"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in printerList"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设置打印份数：">
              <el-select
                v-model="fahuoPrintOption.num"
                placeholder="选择份数"
                size="mini"
              >
                <el-option label="1" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template slot="footer">
        <el-button
          class="footer-btn btn1"
          type="primary"
          size="mini"
          @click="doPrintFahuo"
          >打印发货单</el-button
        >
        <el-button
          class="footer-btn btn2"
          size="mini"
          @click="dialogFahuoPrintVisible = false"
          >取消</el-button
        >
      </template>
    </my-dialog-3> -->
    <!-- 快递单设置发件人弹窗 -->
   <CourierSender :orderInfoList='this.orderInfo' :type='2'  />

    <el-dialog
        :visible.sync="dialogShow"
        width="23%"
        background-color='#fff'
        center>
        <div class="contentClass">
          <i class="el-icon-success"></i>
          <span>成功复制到剪切板！</span>
        </div>
    </el-dialog>
    
    <el-dialog
        :visible.sync="dialog2Show"
        width="23%"
        background-color='#fff'
        :show-close = 'false'
        center>
        <div class="contentClass contentClass2">
          <div>
            <i class="el-icon-warning"></i>
            <span>请勾选打印的商品</span>
          </div>
          <div class="contentFooter">
            <p @click="dialog2Show=false">确定</p>
          </div>
        </div>
    </el-dialog>
    
    <!-- 已打印弹窗 -->
    <reprintDialog1 v-if="dialogShow10" @ok="()=>{
      this.$store.commit('batchprint/SAVE',{dialogFahuoConsignerVisible:true});
      this.dialogShow10=false}" @cancel="()=>{this.dialogShow10=false}" />
    <GetFahuoErrorPopup v-if="GetFahuoError" :dataSrc="GetFahuoError" @closePopup="()=>{this.GetFahuoError=false}"></GetFahuoErrorPopup>

    <!-- 抖音控件下载弹窗 -->
    <selfPopup v-if="download_douyin_popup" text="您还未安装抖音打印控件，无法打印抖音加密订单" confirmText="立即下载" 
      @confirm="download_douyin_fn"
      @cancel="() => { this.download_douyin_popup = false }">
    </selfPopup>
    <!-- 抖音控件下载弹窗 -->
    <selfPopup v-if="download_kuaishou_popup" text="您还未完成快手加密打印设置，无法打印快手加密订单" confirmText="去设置" 
      @confirm="download_kuaishou_fn"
      @cancel="() => { this.download_kuaishou_popup = false }">
    </selfPopup>
    <appDialog @closePopup="()=>{this.print_component_error=false}"  v-if="print_component_error" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getPrinterList } from "@/utils/lodopPrint";
import provinceCity from "@/static/locationOption.json";
import myDialog3 from "@/UI/myDialog3";
import myDialog4 from "@/UI/myDialog4";
import reprintDialog1 from "@/components/dialog/reprintDialog1";
import GetExpressErrorPopup from "@/UI/GetExpressErrorPopup";
import GetFahuoErrorPopup from "@/UI/GetFahuoErrorPopup";
import appDialog from "@/components/appDialog";
import {OSS} from "@/utils/PublicLink";


import ShipFromDialog from "@/components/selectDialog/ShipFromDialog";
import CourierSender from "@/components/selectDialog/CourierSender";

import selfPopup from "@/components/self_popup";


const ashFlag = OSS+ "/ashFlag.png";
const redFlag = OSS+ "/redFlag.png";
const yellowFlag = OSS+ "/yellowFlag.png";
const greenFlag = OSS+ "/greenFlag.png";
const blueFlag = OSS+ "/blueFlag.png";
// const purpleFlag = OSS+ "/purpleFlag.png";

export default {
  name: "ResultInfo",
  components: {
    myDialog3,
    myDialog4,
    reprintDialog1,
    GetExpressErrorPopup,
    GetFahuoErrorPopup,
    appDialog,
    ShipFromDialog,//请选择发货发件人弹窗
    CourierSender,//打印快递弹窗
    selfPopup,
  },
  created() {
    this.provinces = provinceCity[0];
  },
  data() {
    return {
      FLAG_MAP : {
        '1' : redFlag,
        '2' : blueFlag,
        '3' : greenFlag,
        '4' : yellowFlag,
      },
      // 打印机列表
      // printerList: this.PRINTERLIST || window.cainiao_print_list,
      activeFahuoIndex: "0",
      // 默认的渲染窗口
      activeExpressIndex: "0",
      // 底部textarea绑定数据
      textarea: "",
      // 地址输入框控制参数
      addressEdit: false,
      loadingCity: false,
      loadingDistrict: false,
      // 下拉框选项
      // express_list:{
      //   province: '',
      //   city: '',
      //   district: '',
      //   other: ''
      // },
      provinces: [],
      cities: [],
      districts: [],
      // 发货单发货人设置弹窗变量
      dialogFahuoPrintVisible: false,
      fahuoPrintOption: {
        printer: "",
        num: "1",
      },
      // 快递单发货人设置弹窗变量
      expressConsigner: {},
      dialogExpressPrintVisible: false,
      // 发货单弹窗变量
      dialogFahuoVisible: false,
      // 快递单弹窗变量
      dialogExpressVisible: false,
      // 添加快递模板弹窗变量
      dialogAddExpressTemplateVisible: false,
      dialogExpressGlobalSettingsVisible: false,
      expressTemplateListTemp: null,
      SelectedExpressTemplateModifySequenceLabel:0,
      ExpressGlobalSettingsFontFamily:"宋体",
      ExpressGlobalSettingsFontSize:"14",
      ExpressGlobalSettingsFontWeight:"加粗",
      ExpressGlobalSettingsScaleZoom_Transverse: 50, // 快递单横向缩放
      ExpressGlobalSettingsScaleZoom_Longitudinal: 50, // 快递单纵向缩放

      showAll:false,
      flagShow: false,
      dialog2Show: false,
      flagValue: '',      
      falgRadio: '',
      seller_memo_value: '',
      dialogShow: false,
      dialogShow10: false, // 原单号打印的弹窗
      
      GetFahuoError: false, // 发货错误

      print_component_error: false,
      
      addressData:{
        province:'',
        city:'',
        area:'',
        address:'',
        town:'',
        province_code:'',
        city_code:'',
        area_code:''
      },

      ShowEditRemarks:false,
      changeNum:'',
      
      download_douyin_popup: false,
      download_kuaishou_popup: false,
    };
  },
  computed: {
    ...mapState({
      orderId: (state) => state.singleprint.orderId,
      resultList: (state) => state.singleprint.resultList,
      circularType: (state) => state.singleprint.circularType,
      orderInfo: (state) =>state.singleprint.orderInfo,
      decryptInfo:(state)=>state.singleprint.decryptInfo,
      expressTemplateList: (state) => state.print.expressTemplateList,
      expressList: (state) => state.print.expressList,
      // 打印参数
      printExpressTemplate: (state) => state.singleprint.printExpressTemplate,
      printExpressBgimg: (state) => state.singleprint.printExpressBgimg,
      printFahuoTemplate: (state) => state.batchprint.printFahuoTemplate,
      printFahuoBgimg: (state) => state.singleprint.printFahuoBgimg,
      TaobaoAuthURL: (state)=> state.batchprint.TaobaoAuthURL,
      WaybillSite: (state)=> state.batchprint.WaybillSite,
      get_shop_info: (state)=> state.setting.get_shop_info.data,
      expressTemplate: (state)=> state.setting.expressTemplate,
    }),
    addExpressId: {
      get() {
        return this.$store.state.print.addExpressId;
      },
      set(val) {
        this.$store.commit("print/SET_ADD_EXPRESS_ID", val);
      },
    },
    expressTemplate: {
      get() {
        return this.$store.state.batchprint.expressTemplate;
      },
      set(val) {
        this.$store.commit("batchprint/SET_EXPRESS_TEMPLATE", val+'');
      }
    }
  },
  methods: {
    download_douyin_fn(){
      window.open(this.DownLoad_DouYin) 
    },
    download_kuaishou_fn(){
      window.open("/#/guide/kuaishou")
    },
    // 复制成功时的回调函数
    onCopy(e) {
      // this.$message.success("内容已复制到剪切板！");
      this.dialogShow = true
      setTimeout(()=> {
        this.dialogShow = false
      }, 3000)
    },
    // 复制失败时的回调函数
    onError(e) {
      this.$message.error("复制失败！");
    },
    // 清空textarea
    deleteTextarea() {
      this.orderInfo.invoice_content = "";
    },
    provinceChanged(code) {
      if (code != "") {
        var str = "0," + code;
        this.loadingCity = true;
        this.cities = provinceCity[str];
        this.orderInfo.province_code = code;
        this.orderInfo.province = provinceCity[0][code];
        this.loadingCity = false;
        this.nullcity();
        this.nulldistrict();
      }
    },
    cityChanged(code) {
      if (code != "") {
        var str = "0," + this.orderInfo.province_code + "," + code;
        this.loadingDistrict = true;
        this.districts = provinceCity[str];
        this.orderInfo.city_code = code;
        this.orderInfo.city = this.cities[code];
        this.loadingDistrict = false;
        this.nulldistrict();
      }
    },
    cityLoad() {
      if (this.orderInfo.province_code == "") {
        Object.keys(this.provinces).forEach((key) => {
          if (this.provinces[key] == this.orderInfo.province) {
            this.orderInfo.province_code = key;
          }
        });
      }
      var str = "0," + this.orderInfo.province_code;
      this.cities = provinceCity[str];
    },
    districtChanged(code) {
      if (code != "") {
        var str =
          "0," + this.orderInfo.province_code + "," + this.orderInfo.city_code;
        this.orderInfo.area = provinceCity[str][code];
        this.orderInfo.area_code = code;
        // this.resultList.post_code=code;
      }
    },
    districtLoad(index) {
      if (this.orderInfo.city_code == "") {
        Object.keys(this.provinces).forEach((key) => {
          if (this.provinces[key] == this.orderInfo.province) {
            var str = "0," + key;
            this.cities = provinceCity[str];
            Object.keys(this.cities).forEach((key2) => {
              if (this.cities[key2] == this.orderInfo.city) {
                this.orderInfo.city_code = key2;
              }
            });
          }
        });
      }
      var str =
        "0," + this.orderInfo.province_code + "," + this.orderInfo.city_code;
      this.districts = provinceCity[str];
    },
    nullcity() {
      this.orderInfo.city_code = "";
      this.orderInfo.city = "";
    },
    nulldistrict() {
      this.orderInfo.area = "";
      this.orderInfo.area_code = "";
    },
    updateAddress() {
      this.$store
        .dispatch("print/update_order_address", {
          transaction_id: this.orderInfo.transaction_id,
          province: this.orderInfo.province,
          city: this.orderInfo.city,
          area: this.orderInfo.area,
          town: this.orderInfo.town,
          address: this.orderInfo.address,
          create_time: this.orderInfo.create_time,
          areaCode: this.orderInfo.area_code,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.addressEdit = false;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    hideAddressSelect(){
      this.addressEdit = false;
      this.orderInfo.province=this.addressData.province
      this.orderInfo.city=this.addressData.city
      this.orderInfo.area=this.addressData.area
      this.orderInfo.town=this.addressData.town
      this.orderInfo.address=this.addressData.address
      this.orderInfo.province_code=this.addressData.province_code
      this.orderInfo.city_code=this.addressData.city_code
      this.orderInfo.area_code=this.addressData.area_code
    },
    showAddressSelect() {
      this.addressData={
        province:this.orderInfo.province,
        city:this.orderInfo.city,
        area:this.orderInfo.area,
        town:this.orderInfo.town,
        address:this.orderInfo.address,
        province_code:this.orderInfo.province_code,
        city_code:this.orderInfo.city_code,
        area_code:this.orderInfo.area_code
      }
      this.addressEdit = true;
    },
    showInfo(showAll){
      this.showAll=!showAll;
    },
    showExpressPrintDialog() {
      this.dialogExpressPrintVisible = true;
    },
    showFahuoPrintDialog() {
      this.dialogFahuoPrintVisible = true;
    },
    updateFahuoPrintVisible(val){
      this.dialogFahuoPrintVisible=val
    },
    
    // 发货
     handleClickDeliverGoods() {
        let mergeNo=[]//合单
              if(this.orderInfo.merge_order){
                for(let k in this.orderInfo.merge_order){
                  let arr={}
                  arr=this.orderInfo.merge_order[k]
                  mergeNo.push(arr)
                }
              }else{
                let orderNo={}
                orderNo=this.orderInfo
                mergeNo.push(orderNo)
              }
        
          // 查看选择的订单中是够有能拆分的单子
            if(this.orderInfo.is_merge_order==1 && this.orderInfo.merge_no==''){
             this.$message.error('当前合单未打印过快递单，请先打印快递单，再进行发货操作')
              return false
            }else{
              this.GetFahuoError=false
              this.$store.dispatch("batchprint/OpDeliverySendOrder",{data:mergeNo}).then(res=>{
                if(res.code == "0"){
                  this.GetFahuoError = res.message
                 
                }else{
                  this.$message.success("发货成功")
                }
                 
                this.$store.dispatch("batchprint/get_search_result")
            })
            }
 
    },

    // =======================
    circularTypeChange(num) {
      let resultList = JSON.parse(JSON.stringify(this.resultList))
      let arr = []
      for(let i in resultList.data) {
        arr.push(resultList.data[i].transaction_id)
      }
      if(arr.indexOf(this.orderId)!==-1) {
        resultList.data[arr.indexOf(this.orderId)].circularType = num
      }
      this.$store.commit("singleprint/SET_RESULT_LIST", resultList);
      let orderInfo = this.orderInfo
      orderInfo.circularType = num
      this.$store.commit("singleprint/SET_ORDERINFO", orderInfo);
      let params = {
        orderId: orderInfo.transaction_id,
        create_time: orderInfo.create_time,
        sign: num
      }
      this.$store
      .dispatch("singleprint/updateSign", params)
      .then((res) => {
			this.$message.error(res.message);
		});
    },
    editNameBtn(item,index) {
      // this.editName = !this.editName
      this.orderInfo.order_sub[index].inputType = true
      if(item.sku_short_name != '') {
        this.orderInfo.order_sub[index].inputValue  = item.sku_short_name
      }else {
        if(item.baby_short_name != '') {
          this.orderInfo.order_sub[index].inputValue  = item.baby_short_name
        }else {
          this.orderInfo.order_sub[index].inputValue  = item.name
        }
      }
    },
    noneEditNameBtn(item, index) {
      this.orderInfo.order_sub[index].inputType = false
    },
    confirmEditNameBtn(item, index) {
    },
    async printDeliverGoodsBtn() {
      try {
        let lodop_printer = await getPrinterList();
        if(this.orderInfo.invoice_print_state=="1" ){
          this.dialogShow10 = true
        }else{
          this.$store.commit('batchprint/SAVE',{dialogFahuoConsignerVisible:true})
        }
      } catch (error) {
        this.print_component_error = true
      }
    },
    printExpressBtn() {
      if(window.cainiao_print_error || !window.cainiao_print_list){
        this.print_component_error = true
        return false;
      }
      // 抖音订单未安装打印控件
      if ( this.orderInfo["outcode"] == 'douyin' && !window.douyin_print_list) {
        this.download_douyin_popup = true
        return false
      }
      // 抖音订单未安装打印控件
      if ( this.orderInfo["outcode"] == 'kuaishou' && !window.kuaishou_print_list) {
        this.download_kuaishou_popup = true
        return false
      }
      let arr = []
      for(let i in this.orderInfo.order_sub) {
        if(!this.orderInfo.order_sub[i].checkedType) {
          arr.push(this.orderInfo.order_sub[i].checkedType)
        }
      }
      if(arr.length>0) {
        this.dialog2Show = true
      }else{
        this.$store.commit('batchprint/SAVE',{dialogExpressConsignerVisible:true})
      }
    },
    // 获取网点
    GetWaybillSearch(){
      this.$store.dispatch("batchprint/GetWaybillSearch",{cp_code: this.expressTemplateList[this.activeExpressIndex].company_no });
    },
    CancelAuth(){
      this.$store.dispatch("batchprint/CancelAuth").then(res=>{
        this.GetWaybillSearch() // 取消授权后重新获取网点信息
      })
    },
    changeFlag(value) {
      this.flagValue_temp = value
    },  
	  changeMemo() {
      this.flagShow = true
      this.seller_memo_value = this.orderInfo.seller_memo
    },
    saveMemo() {
      if(this.flagValue_temp) {
        this.orderInfo.seller_remark_icon = this.flagValue_temp
        this.resultList.data.map(item=>{
          if(item.transaction_id == this.orderInfo.transaction_id) {
            item.seller_remark_icon = this.flagValue_temp
          }
        })
        
        let params = {
          orderId: [this.orderInfo.transaction_id],
          remarkIcon: this.falgRadio,
          memo: this.seller_memo_value,
          create_time: [this.orderInfo.create_time]
        }
        this.$store
        .dispatch("singleprint/OrderMemoAdd", params)
        .then((res) => {
          this.$message.success(res.message);
          this.orderInfo.seller_memo = this.seller_memo_value
          this.flagShow = false 
        });
      }else {
        this.$message.error("请选择旗子");
      }
      
    },
       // 合单修改备注插旗
    changeMemoHe(seller_memo,ind,merge_order) {

      this.seller_memo_value=seller_memo
      this.changeNum=ind
      this.ShowEditRemarks=false
      merge_order[ind].editRemarks=true
      for(let i in merge_order){
        merge_order[i].editRemarks=false
        if(ind==i){
        merge_order[i].editRemarks=true
        }
      }
      this.ShowEditRemarks=true
    }, 
    changeFlagHe(value,ite){
      ite.falgRadio_temp = value;
    },
     saveMemoHe(ite,ind,index){
      if(ite.falgRadio_temp) {
        // this.resultList.data.map(item=>{
        //   if(item.transaction_id == this.orderInfo.transaction_id) {
        //     item.seller_remark_icon = this.flagValue_temp
        //   }
        // })
        let params = {
          orderId: [this.orderInfo.merge_order[ind].transaction_id],
          remarkIcon: ite.falgRadio_temp,
          memo: this.seller_memo_value,
          create_time: [ this.orderInfo.merge_order[ind].create_time]
        }
        this.$store
        .dispatch("singleprint/OrderMemoAdd", params)
        .then((res) => {
          this.$message.success(res.message);
          this.resultList.data[index].merge_order[ind].seller_memo = this.seller_memo_value
          this.resultList.data[index].merge_order[ind].seller_remark_icon = ite.falgRadio_temp
          this.orderInfo.merge_order[ind].seller_remark_icon = ite.falgRadio_temp
          this.orderInfo.merge_order[ind].seller_memo = this.seller_memo_value
          // this.orderInfo.merge_order[ind].seller_memo_value = ite.seller_memo_value
          // this.orderInfo.merge_order[ind].seller_remark_icon = ite.seller_remark_icon
          //  this.orderInfo.merge_order[ind].seller_remark_icon= ite.seller_memo_value
          //  this.orderInfo.merge_order[ind].seller_memo_value= ite.seller_memo_value
          this.orderInfo.merge_order[ind].editRemarks=false 
          // this.resultList.data[ind].merge_order[this.changeNum].seller_remark_icon= ite.seller_remark_icon
          // this.resultList.data[ind].merge_order[this.changeNum].seller_memo= this.seller_memo_value
        });
     
      }else {
        this.$message.error("请选择旗子");
      }
    }
  },
};
</script>

<style lang="less" scoped>
.result-container {
  width: 100%;
  height: 100%;
}
.row1 {
  .body-infotable {
    font-size: 12px;
    margin-left: 10px;
    .table-key {
      display: inline;
      font-weight: 600;
      color: #b9b1a7;
      margin-right: 10px;
    }
    /deep/ .el-form-item__content {
      line-height: 0;
    }
    /deep/ .el-select {
      width: 120px;
    }
  }
}
.row2 {
  margin: 5px 0;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #eeeeee;
  .row2-key {
    margin-right: 10px;
    font-weight: 600;
  }
  .row2-item {
    color: #a8a8a8;
    margin-right: 10px;
  }
  .row-item-active {
    color: #f28b38;
    font-size: 13px;
    font-weight: 1000;
  }
}
.row3 {
  .row3-header {
    font-size: 13px;
    font-weight: 1000;
    margin: 5px 0;
    padding: 10px;
    background-color: #efefef;
    display: flex;
    justify-content: space-between
  }
  .row3-body {
    .body-infotable2 {
      font-size: 12px;
      margin-left: 10px;
      .table-key {
        display: inline;
        font-weight: 600;
        color: #b9b1a7;
        margin-right: 10px;
      }
    }
  }
}
/deep/ .iconfont {
  font-size: 12px;
}
/deep/ .el-checkbox__label {
  font-size: 13px;
  color: #b8b8b8;
}
.row4 {
  .row4-table {
    margin: 10px 0;
    width: 100%;
    border: 0.5px solid #ebebeb;
    border-spacing: 0;
    td {
      border: 0.5px solid #ebebeb;
      padding: 10px;
    }
    .table-image {
      width: 200px;
    }
    .table-content {
      display: flexbox;
      flex-direction: column;
      .table-key {
        padding-right: 20px;
      }
    }
    .inner-table {
      margin: 5px 0;
      td {
        border: none;
        padding: 3px 0;
      }
    }
  }
}
.row5 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  position: relative;
  .row5-col1 {
    width: 400px;
    .col1-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;
    }
  }
  .row5-col2 {
    display: flex;
    // flex-direction: column;
    .col2-btn {
      margin: 5px 10px;
      background-image: none;
      color: #fff;
    }
    .btn1 {
      background-color: #23b7e5;
    }
    .btn2 {
      background-color: #02b77e;
    }
  }
  .row5-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 20px;
    color: #fff;
    background-image: -webkit-linear-gradient(bottom, #055ae3, #057ce9);
  }
}
.row6 {
  margin: 20px 0;
}
.row1-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}
.contentClass {
  height: 170px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: #00bb9c;
    font-size: 50px;
    line-height: 50px;
  }
  span {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    margin-left: 10px;
  }
}
.contentClass2 {
  justify-content: left;
  align-items: flex-start;
  flex-wrap: wrap;
  color: #ff7614;
  font-size: 22px;
  div:nth-child(1) {
    display: flex;
    align-items: center;
    height: 117px;
  }
  i {
    color: #ff7614;
    font-size: 40px;
    // line-height: 110px;
    margin-left: 25px;
  }
  .contentFooter {
    height: 53px;
    width: 100%;
    background-color: #f3f3f3;
    border-top: 1px solid #eaeaea;
    text-align: right;
    p {
      font-size: 15px;
      line-height: 15px;
      background-color: #ff7614;
      color: #fff;
      display: inline-block;
      padding: 9px 13px;
      border-radius: 4px;
      margin: 10px;
      cursor: pointer;
    }
  }
}
/deep/ .el-dialog {
    background-color: #fff;
  }
  /deep/ .el-dialog__header {
      padding: 0;
  }
  /deep/ .el-dialog__body {
      padding: 0;
  }
  /deep/ .el-dialog__close{
      font-size: 20px;
      font-weight: 600;
      color: #2c2b3b;
  }
  /deep/ .el-dialog__close:hover {
      color: #2c2b3b;
  }
  .circularClass {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    border: 2px solid #fb0404;
    margin-right: 5px;
    cursor: pointer;
  }
  .el-icon-edit {
    font-size: 14px;
    line-height: 14px;
    margin-left: 10px;
  }
  /deep/ .table-value .el-input__inner {
    border: none;
    color: #ff0000;
    font-weight: 600;
    padding: 0px;
    border-bottom: 1px solid #8dacc3;
        
  }
  /deep/ .table-value .el-input {
    width: 800px;
  }
  .flagClass {
    margin-bottom: -3px;
    margin-right: 10px;
  }
  
.el-button--text {
    font-size: 13px;
    color: #adadad;
    font-weight: 600;
    padding: 0;
    text-decoration:underline
    
}
.editClass {
  color: #3366cc;
  margin-left: 10px;
}
.editNameClass {
  width: 400px;
  height: 35px;
  background-color: #f4f4f4;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  span {
    color: #f7881d;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
  }
}
/deep/ .editNameClass .el-input input {
  border: 1px solid #c8c8c8;
}
.editBtn {
  font-size: 12px;
  margin-left: 10px;
}
.express-tab-btn-box{
  position: absolute;
  top: -40px;
  height: 40px;
  left: 510px;
  display: flex;
  background-color: #333;
  border-radius: 5px 5px 0 0;
}
.btn-container{
  margin: auto 10px;
  position: relative;
}
.btn-container:not(:first-child)::before{
  content: "|";
  position: absolute;
  left: -10px;
}
.express-pro-box{
  display: flex;
}
.modifyExpressTemplateSequenceBox{
  position: absolute;
  top: -40px;
  background-color: #4f4f4f;
  z-index: 3;
  width: 848px;
}
.ExpressTemplateSaveSequenceBox{
  vertical-align: top;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0px;
  width: 130px;
}
.ExpressTemplateSaveSequenceBtn{
  width: 80px;
  margin: 5px 0px;
}
.ExpressTemplateModifySequenceBox{
  font-size: 12px;
  padding: 8px 5px;
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: wrap;
  width: 714px;
  min-height: 100px;
  background-color: #333;
  color: #ebb563;
}
.ExpressTemplateModifySequenceLabel{
  width: 100px;
  height: 32px;
  line-height: 32px;
  margin: 5px 20px;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.ExpressTemplateModifySequenceLabelLeft{
  position: absolute;
  background-color: #dfdfdf;
  border-radius: 5px 0px 0px 5px;
  left: -20px;
  width: 20px;
  user-select: none;
}
.ExpressTemplateModifySequenceLabelRight{
  position: absolute;
  background-color: #dfdfdf;
  border-radius: 0px 5px 5px 0px;
  right: -20px;
  width: 20px;
  user-select: none;
}
.ExpressTemplateModifySequenceLabelText{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  padding: 0px 20px;
}
.ExpressTemplateModifySequenceLabelDelete{
  position: absolute;
  right: 0px;
  color: #999999;
  font-weight: bold;
  width: 20px;
  font-size: 12px;
  top: 1px;
}
.ExpressGlobalSettingsBox{
  display: flex;
  height: 100%;
}
.ExpressGlobalSettingsBoxLeft{
  height: 475px;
  width: 390px;
  border-right: 1px solid #6b6e76;
}
.ExpressGlobalSettingsLabel{
  margin-bottom: 14px;
  padding-left: 42px;
}
.ExpressGlobalSettingsItemName{
  display: inline-block;
  margin-right: 20px;
}
.FahuoGlobalSettingsItemName{
  display: inline-block;
  width: 70px;
  text-align: end;
  margin-right: 20px;
  color: #999999;
}
.ExpressGlobalSettingsItemAllSetKDList{
  height: 120px;
  overflow-y: auto;
  padding-left: 4px;
  width: 250px;
  display: inline-block;
  vertical-align: top;
}
.ExpressGlobalSettingsItemAllSetKDListItem{
  background: #eee;
  border-radius: 2px;
  color: #425b6d;
  float: left;
  line-height: 20px;
  margin: 0 10px 10px 0;
  min-height: 20px;
  padding: 0 14px 0 10px;
  position: relative;
}
.ExpressGlobalSettingsItemAllSetKDListItemClose{
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 2px;
}
.ExpressGlobalSettingsItemAllSetKDListItemAddExpress{
  cursor: pointer;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin: 4px 0 0 112px;
  padding-left: 18px;
  text-decoration: none;
  width: 80px;
  border-radius: 20px;
  background-color: #f39f27;
}
.ExpressGlobalSettingsItemTips{
  margin: 20px 20px 0px 0px;
  color: #fff;
}
.FahuoGlobalSettingsItemTips{
  margin: 20px 20px 0px 0px;
  color: #999999;
}
.ExpressGlobalSettingsBoxRight{
  padding-left: 28px;
  width: 390px;
}
.ExpressGlobalSettingsZoomBox{
  display:flex;
  align-items: center;
  padding-left: 42px;
}
.ExpressAttrBox{
  flex: 1;
  padding: 30px 20px 0;
  background: #4e5159;
}
.ExpressAttrTitle{
  color: #eb9e26;
  font-size: 12px;
  line-height: 32.94px;
  margin: 10px 0px;
}
.ExpressAttrShopName{
  border-bottom: 1px solid #6e7178;
  padding: 0 0 8px;
  color: #fff;
  margin: 20px 0 4px;
}
.ExpressAttrShopOpenServiceTips{
  margin-top: 10px;
  color: #adadad;
  font-size: 12px;
}
.ExpressAttrShopOpenServiceLink{
  margin: 10px 0px;
  color: #b9cede;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}
.FahuoGlobalSettingsItemInput{
  width: 200px;
  padding: 2px 5px;
  outline:none;
  font-size: 12px;
}
.FahuoGlobalSettingsItemTextarea{
  padding: 2px 5px;
  font-size: 12px;
  outline:none;
  width: 202px;
  height: 48px;
  resize: none;
  vertical-align: top;
}
.RefreshAuthorization{
  display: inline-block;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  background: #f6891e;
  margin-top: 10px;
}
.noDataClass {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  div {
    display: flex;
    align-items: center;
    p:nth-child(1) {
      width: 5px;
      height: 5px;
      background-color: #f5821f;
      border-radius: 50%;
      margin-right: 10px;
    }
    p:nth-child(2) {
      color: #333;
      font-size: 16px;
      line-height: 16px;
    }
  }
  div:nth-child(2) {
    padding-top: 15px;
  }
}
.DeleteShop{
  color: #bdcfde;
  font-size: 14;
  text-decoration: underline;
  cursor: pointer;
}
.WaybillSiteAddress{
  margin-top: 10px;
  color: #9c9a9c;
}
.WaybillSiteNumber{
  margin-top: 10px;
  color: #adaead;
}
.WaybillSiteInfoBox{
  margin-top: 10px;
  background: #394142;
  border: #313839;
  border-radius: 5px;
  padding: 10px;
}
.WaybillSiteInfoItem{
  display: flex;
}
.WaybillSiteInfoItemRight{
  width: 190px;
  line-height: 1.5;
}
.WaybillSiteInfoItemText{
  word-break:break-all;
  white-space:pre-wrap;
  color: #9c9a9c;
}
.WaybillSiteInfoItemTips{
  color: #00ba9c;
}
.preview-express-box{
  width:800px;height:560px; overflow: auto;padding: 20px; box-sizing: border-box;
}
.preview-express-box img{
  border: 1px solid #eee;
}
.flagDiv {
  display: inline-block;
  position: absolute;
  top: 55px;
  left: 15PX;
  border: 1px solid #aaa;
  padding: 10px 20px;
  .el-radio {
    margin: 0;
  }
  .handleBtn {
    margin-left: 10px;
    font-size: 12px;
    line-height: 12px;
  }
}</style>